import React from 'react';
import { richText } from 'components/utils/helpers';

const BlockTextText = ({ data, className = '' }) => {
  return (
    <section className={`block pv3 pv4-xl pv5-xxl ${className}`}>
      {data.title && data.title.length > 0 && (
        <h3 className="body-header mb3 mb4-md mb5-xl ph3 ph4-xl ttu">
          {data.title}
        </h3>
      )}
      <div className="flex flex-column flex-row-md body-copy">
        <div className="w-100 w-50-md body-copy ph3 ph4-xl ph5-xxl">
          {richText(data.textLeft.json)}
        </div>
        <div className="w-100 w-50-md body-copy ph3 ph4-xl ph5-xxl">
          {richText(data.textRight.json)}
        </div>
      </div>
    </section>
  );
};

export default BlockTextText;
