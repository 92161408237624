import React from 'react';
import Img from 'gatsby-image';

const Image = ({ image, className = '', loading }) => {
  return (
    <Img
      fluid={image.fluid}
      className={className}
      loading={loading || 'lazy'}
      style={{
        userSelect: 'none'
      }}
      alt={image.description}
    />
  );
};

export default Image;
