import React from 'react';
import { richText } from 'components/utils/helpers';
import classnames from 'classnames';

const BlockRichText = ({ data, className = '' }) => {
  return (
    <section className={`block pv3 pv4-xl pv5-xxl ${className}`}>
      {data.title && data.title.length > 0 && (
        <h3 className="body-header mb3 mb4-md mb5-xl ph3 ph4-xl ttu">
          {data.title}
        </h3>
      )}
      <div className="flex flex-column flex-row-md body-copy">
        <div
          className={classnames('w-100 body-copy ph3 ph4-xl ph5-xxl', {
            'body-copy__two-column': data.twoColumn
          })}
        >
          {richText(data.description.json)}
        </div>
      </div>
    </section>
  );
};

export default BlockRichText;
